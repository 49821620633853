/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

var isSmDown = (window.innerWidth < 768) ? true : false;
var isMdUp = (window.innerWidth >= 768) ? true : false;

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        $('.dotdotdot').dotdotdot();
        $('.sharing a').on('click', function(event) {
          if ( !$(this).parent().hasClass('email') ) {
            event.preventDefault();
            var url = $(this).attr('href');
            var winWidth = 600,
                winHeight = 400;
            var winTop = (screen.height / 2) - (winHeight / 2);
            var winLeft = (screen.width / 2) - (winWidth / 2);
            window.open(url, 'sharer', 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width=' + winWidth + ',height=' + winHeight);
          }

        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        $(".royalSlider.main-gallery").royalSlider({
          keyboardNavEnabled: true,
          imageScalePadding: 0,
          loop: true,
          autoScaleSlider:false,
          autoHeight: false,
          imageScaleMode: 'fill',
          slidesSpacing: 0,
          transitionType: 'fade',
          transitionSpeed: 1000,
          autoPlay: {
            enabled: true,
            pauseOnHover: false,
            delay: 5000,
          }
        });
        $(".royalSlider.social").royalSlider({
          keyboardNavEnabled: false,
          imageScalePadding: 0,
          loop: true,
          autoScaleSlider:false,
          autoHeight: false,
          imageScaleMode: 'fill',
          slidesSpacing: 0,
          transitionSpeed: 1000,
          controlNavigation: 'none',
          arrowsNavAutoHide: false,
          // autoPlay: {
          //   enabled: true,
          //   pauseOnHover: false,
          //   delay: 5000,
          // }
        });

        TC.unifyPostHeights();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      },

    },
    // About us page, note the change from about-us to about_us.
    'about': {
      init: function() {
        $(".royalSlider").royalSlider({
            keyboardNavEnabled: true,
            imageScalePadding: 0,
            loop: true,
            autoScaleSlider:false,
            autoHeight: false,
            imageScaleMode: 'fill',
            slidesSpacing: 0,
            transitionType: 'fade',
            transitionSpeed: 1000,
            autoPlay: {
              enabled: true,
              pauseOnHover: false,
              delay: 5000,
            }
        });
        $('.popup-modal').magnificPopup({
          type: 'inline',
          closeBtnInside: true,

        });
      }
    },
    'at_work' : {
      init: function() {

        $('.subnav a').on('click', function(event) {
          event.preventDefault();
          //var classes = $(this).parent().attr('class');
          //var catID = parseInt(classes.substring(classes.indexOf('cat-item-')+9),10);
          var catName = "All Projects";
          $('.project').hide();
          $('.blurb.active').removeClass('active');

          if($(this).parent().hasClass('all')) {
            $('.work-list-item').each(function(index, el) {

              $(this).parent().delay(index*80).fadeIn(300);

            });
            $('.blurb-all').addClass('active');
          }else {
            catName = $(this).html();
            var catSlug = convertToSlug( catName );
            //console.log(catSlug);

            $('.category-'+catSlug).each(function(index, el) {

              $(this).parent().delay(index*80).fadeIn(300);

            });
            $('.blurb-'+catSlug).addClass('active');
          }
          $('.subnav li.active').removeClass('active');
          $(this).parent().addClass('active');

          var slug = convertToSlug($(this).text());
          jQuery.bbq.pushState('#'+slug);
        });

        $(window).bind( 'hashchange', function(e) {
          var url = $.param.fragment();
          if(url) {
            $('.subnav li.'+url+' a').trigger('click');
          }

        });
        $(window).trigger( 'hashchange' );

      }
    },
    'single' : {
      init: function() {
        $(".royalSlider").royalSlider({
            keyboardNavEnabled: true,
            imageScalePadding: 0,
            loop: true,
            autoScaleSlider:false,
            autoHeight: false,
            imageScaleMode: 'fill',
            slidesSpacing: 0,
            transitionType: 'fade',
            transitionSpeed: 1000,
            globalCaption: true,
            autoPlay: {
              enabled: true,
              pauseOnHover: false,
              delay: 5000,
            }
        });
      }
    },
    'at_large' : {
      init: function() {
        TC.unifyPostHeights();

        $('.subnav li').eq(0).addClass('active');
        $('.subnav a').on('click', function(event) {
          event.preventDefault();

          $('.subnav li.active').removeClass('active');
          $(this).parent().addClass('active');

          var activeIndex = $('.subnav li.active').index();
          $('.tab-content.active').removeClass('active');
          $('.tab-content').eq(activeIndex).addClass('active');
          var slug = $(this).text().toLowerCase();
          jQuery.bbq.pushState('#'+slug);
        });

        $(window).bind( 'hashchange', function(e) {
          var url = $.param.fragment();

          if(url) {
            $('.subnav li.'+url+' a').trigger('click');
          }
        });
        $(window).trigger( 'hashchange' );
      }
    },
    'blog' : {
      init: function() {
        //$('.subnav li').eq(0).addClass('active');
        $(".royalSlider").royalSlider({
            keyboardNavEnabled: true,
            imageScalePadding: 0,
            loop: true,
            autoScaleSlider:false,
            autoHeight: false,
            imageScaleMode: 'fill',
            slidesSpacing: 0,
            transitionType: 'fade',
            transitionSpeed: 1000,
            globalCaption: true,
            autoPlay: {
              enabled: true,
              pauseOnHover: false,
              delay: 5000,
            }
        });
      }
    },
  };

  var TC = {
    unifyPostHeights: function () {
      // Get the largest height
      var largestHeight = 0;

      document.querySelectorAll('.news-list-item').forEach(function(element) {
        if (element.offsetHeight > largestHeight) {
          largestHeight = element.offsetHeight;
        }
      });

      // Set all post items to that height
      document.querySelectorAll('.news-list-item').forEach(function(element) {
        if (isMdUp) {
          element.style.height = largestHeight + 'px';
        }
      });
    }
  }

  var convertToSlug = function(Text)
  {
      return Text
          .toLowerCase()
          .replace(/ /g,'-')
          .replace(/[^\w-]+/g,'')
          .replace('-amp-','-and-')
          .replace('--','-')
          ;
  };
  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
